import auth from '@/middleware/auth.js'

export default [{
    path: '',
    component: () =>
        import ('@/layouts/Constelacion.vue'),
    children: [
        {
            path: '/bpb_apps_admin',
            name: 'bpb_apps_admin',
            component: () =>
                import ('@/apps/bpb_apps_admin/Dashboard'),
            meta: {
                title: 'Dashboard Aplicaciones',
                middleware: auth
            }
        }
        ,{
            path: '/bpb_apps_admin/index',
            name: 'bpb_apps_aplicaciones_index',
            component: () =>
                import ('@/apps/bpb_apps_admin/pages/Aplicaciones.vue'),
            meta: {
                title: 'Aplicaciones',
                middleware: auth
            }
        }
    ]
}]
